import PropTypes from 'prop-types';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import Input from 'components/base-components/Input';
import React from 'react';
import { t } from 'i18next';
import { Field } from 'react-final-form';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from 'components/base-components/IconButton';
import Card from 'components/base-components/Card';
import classNames from 'classnames';
import Icon from 'components/base-components/Icon';
import TitleComponent from '../shared/title.component';

const MushroomFormComponent = ({ isVisible, mushroomData, values }) => {
  if (!isVisible) return;
  return (
    <Card className="plant-cultivation__card">
      <TitleComponent
        title={t('plant_cultivation.plant_types.mushroom_farming')}
        icon="mushroom_farming"
      />
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked': values['mushroom_open_checkbox'],
        })}
      >
        <Field
          name="mushroom_open_checkbox"
          type="checkbox"
          initialValue={
            mushroomData?.openFieldInAres ||
            mushroomData?.champignonsQuantityInTonnes ||
            mushroomData?.trufflesQuantityInTonnes
          }
        >
          {({ input }) => (
            <CheckBox
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.shared.open_field')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="mushroom_open_field_in_ares"
          initialValue={mushroomData?.openFieldInAres}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.mushroom.surface_area_ares')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.mushroom_open_checkbox}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked': values['mushroom_covered_checkbox'],
        })}
      >
        <Field
          name="mushroom_covered_checkbox"
          type="checkbox"
          initialValue={
            mushroomData?.coveredFieldInAres || mushroomData?.noblesQuantityInTonnes
          }
        >
          {({ input }) => (
            <CheckBox
              isChecked={input.checked}
              size="tiny"
              label={
                <div>
                  <span className="plant-cultivation__method--open__tooltip-label">
                    {t('plant_cultivation.shared.covered_cultivation')}
                  </span>
                  <span>
                    <Tooltip
                      content={t('plant_cultivation.shared.covered_cultivation_info')}
                      type="inverse"
                      position="right-top"
                      size="small"
                      showArrow={true}
                    >
                      <IconButton
                        icon={<Icon name="info" size="tiny" />}
                        color="default"
                        size="tiny"
                        isIconOnly={true}
                      />
                    </Tooltip>
                  </span>
                </div>
              }
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="mushroom_covered_field_in_ares"
          initialValue={mushroomData?.coveredFieldInAres}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.mushroom.surface_area_ares')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.mushroom_covered_checkbox}
              {...input}
            />
          )}
        </Field>
      </div>
      <Card className="plant-cultivation__method--mushroom-card">
        <div className="plant-cultivation__method--mushroom">
          <div>{t('plant_cultivation.mushroom.champignons')}</div>
          <Field
            name="mushroom_champigon_quantity_in_tons"
            initialValue={mushroomData?.champignonsQuantityInTonnes}
          >
            {({ input }) => (
              <Input
                size="tiny"
                label={t('plant_cultivation.mushroom.other_placeholder')}
                placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                {...input}
              />
            )}
          </Field>
        </div>

        <div className="plant-cultivation__method--mushroom">
          <div className="plant-cultivation__mushroom--noble__label">
            <span>{t('plant_cultivation.mushroom.noble_mushrooms')}</span>
            <span>
              <Tooltip
                content={t('plant_cultivation.mushroom.noble_mushrooms_info')}
                type="inverse"
                position="right-top"
                size="small"
                showArrow={true}
              >
                <IconButton
                  icon={<Icon name="info" size="tiny" />}
                  color="default"
                  size="tiny"
                  isIconOnly={true}
                />
              </Tooltip>
            </span>
          </div>
          <Field
            name="mushroom_nobles_quantity_in_tonnes"
            initialValue={mushroomData?.noblesQuantityInTonnes}
          >
            {({ input }) => (
              <Input
                size="tiny"
                label={t('plant_cultivation.mushroom.other_placeholder')}
                placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                {...input}
              />
            )}
          </Field>
        </div>

        <div className="plant-cultivation__method--mushroom">
          <div>{t('plant_cultivation.mushroom.truffles')}</div>
          <Field
            name="mushroom_truffles_quantity_in_tons"
            initialValue={mushroomData?.trufflesQuantityInTonnes}
          >
            {({ input }) => (
              <Input
                size="tiny"
                label={t('plant_cultivation.mushroom.other_placeholder')}
                placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                {...input}
              />
            )}
          </Field>
        </div>
      </Card>
    </Card>
  );
};

MushroomFormComponent.defaultProps = {
  isVisible: false,
  mushroomData: {},
  values: {},
};

MushroomFormComponent.propTypes = {
  isVisible: PropTypes.bool,
  mushroomData: PropTypes.shape(),
  values: PropTypes.shape(),
};

export default MushroomFormComponent;
