import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from 'components/pdf/styles/styles';
import BioLogo from 'assets/bio_logo_large.png';
import { Break } from 'components/pdf/shared/shared-components';
import ProducerCoverLetterContent from 'components/pdf/producer-contract/cover-letter/cover-letter-content';
import ProducerContractFooter from '../footer.view';
import producerCoverLetterStyles from './producer-cover-letter';

const ProducerCoverLetterPdf = ({ organisation, user }) => {
  return (
    <Document>
      <Page size="A4" style={[styles.page, producerCoverLetterStyles.page]}>
        <Image style={producerCoverLetterStyles.headerLogo} src={BioLogo} />
        <View style={producerCoverLetterStyles.body}>
          <Text>
            <ProducerCoverLetterContent organisation={organisation} user={user} />
            <Break count={2} />
          </Text>
        </View>
        <ProducerContractFooter showInLastPageOnly={true} />
      </Page>
    </Document>
  );
};

ProducerCoverLetterPdf.propTypes = {
  organisation: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default ProducerCoverLetterPdf;
