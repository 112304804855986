import { useGetOrganizationQuery } from 'api/organizations';
import SelectControlBody from 'components/control-body/select-control-body.component';
import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const ControlBodyForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: organization = {} } = useGetOrganizationQuery(id);
  const controlBodyId = organization.controlBodyOrganizationId;

  return (
    <Fragment>
      <div className="organisation__form--font-bold">{t('organisation_overview.control_body')}</div>
      <div className="organisation__form-sub-label organisation__form-contacts-placeholder">
        <Trans
          i18nKey="organisation.form.control_body.explanation"
          components={{
            anchor1: (
              <a
                href="https://www.bio-test-agro.ch/de/"
                target="_blank"
                rel="noreferrer"
                className="organisation__form-sub-label--color"
              />
            ),
            anchor2: (
              <a
                href="https://www.bio-inspecta.ch/en/home"
                target="_blank"
                rel="noreferrer"
                className="organisation__form-sub-label--color"
              />
            ),
          }}
        />
      </div>
      <div className="organisation__form--margin-top organisation__form-control-body">
        <SelectControlBody
          label={t('organisation_overview.control_body')}
          controlBodyId={controlBodyId}
        />
      </div>
    </Fragment>
  );
};

export default ControlBodyForm;
