import { Image, Link, Text, View } from '@react-pdf/renderer';
import JuniorSign from 'assets/junior_sign.png';
import AdminSign from 'assets/tvd_admin_sign.png';
import { formatAddressForPDF } from 'components/pdf/anerkennung/helpers/organization-address';
import { Break, ListItem } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';

const bioInspectaEmail = 'sekretariat@bio-inspecta.ch';
const vignettenEmail = 'vignetten@bio-suisse.ch';
const vignettenWebsite = 'www.bio-suisse.ch/vignetten';

const FirstPage = ({ organization, address }) => (
  <>
    <View style={tvdStyles.contentBody}>
      <Text>
        <Break count={2} />
        <Text>{organization?.name}</Text>
        <Break />
        {formatAddressForPDF(address, false)}
        <Break count={3} />
        <Text>{t('pdf.tvd.current_date', { date: dateMonthYearFormatter(new Date()) })}</Text>
        <Break count={2} />
        <Text style={tvdStyles.textBold}>{t('pdf.tvd.subject')}</Text>
        <Break count={3} />
        <Text>{t('pdf.tvd.intro.line1')}</Text>
        <Break count={2} />
        <Text>{t('pdf.tvd.intro.line2')}</Text>
        <Break count={2} />
        <Text style={tvdStyles.textBold}>{t('pdf.tvd.intro.line3')}</Text>
        <Break count={2} />
        <Text>
          {t('pdf.tvd.intro.line4')}
          <Link src={`https://${vignettenWebsite}`}>{vignettenWebsite}</Link>
        </Text>
        <Break count={2} />
        <Text>{t('pdf.tvd.intro.line5')}</Text>
        <Break count={2} />
        <Text>
          {t('pdf.tvd.intro.line6.part1')}
          <Link src={`mailto:${vignettenEmail}`}>{vignettenEmail}</Link>
          {t('pdf.tvd.intro.line6.part2')}
          <Link src={`mailto:${bioInspectaEmail}`}>{bioInspectaEmail}</Link>
          {t('pdf.tvd.intro.line6.part3')}
          <Link src={`https://${vignettenWebsite}`}>{vignettenWebsite}</Link>
          {t('pdf.tvd.intro.line6.part4')}
        </Text>
        <Break count={2} />
        <Text>{t('pdf.tvd.intro.line7')}</Text>
        <Break count={2} />
        <Text>
          <Trans i18nKey="pdf.tvd.intro.line8" components={{ break: <Break /> }} />
        </Text>
      </Text>
    </View>
    <View style={[tvdStyles.contentBody, tvdStyles.flexRow]}>
      <View style={tvdStyles.flexRowItem}>
        <Image style={tvdStyles.signImage} src={JuniorSign} />
        <Text>
          Luca Müller
          <Break />
          {t('pdf.tvd.designation.junior')}
        </Text>
      </View>
      <View style={tvdStyles.flexRowItem}>
        <Image style={tvdStyles.signImage} src={AdminSign} />
        <Text>
          Corinne Salvisberg
          <Break />
          {t('pdf.tvd.designation.admin')}
        </Text>
      </View>
    </View>
    <View style={{ ...tvdStyles.contentBody, ...tvdStyles.marginTop10 }}>
      <Text>{t('pdf.tvd.extra.line1')}</Text>
      <ListItem listStyle="dash" style={tvdStyles.tvdListItemStyle}>
        {t('pdf.tvd.extra.line2')}
      </ListItem>
      <ListItem listStyle="dash" style={tvdStyles.tvdListItemStyle}>
        {t('pdf.tvd.extra.line3')}
      </ListItem>
    </View>
  </>
);

FirstPage.propTypes = {
  organization: PropTypes.shape().isRequired,
  address: PropTypes.shape().isRequired,
};

export default FirstPage;
