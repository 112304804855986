import Input from 'components/base-components/Input';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import './farmland.styles.scss';
import moment from 'moment';
import SelectBox from 'components/base-components/SelectBox';
import { useGetOrganisationStartingYearsQuery } from 'api/organizations';
import EnsureTransparencyComponent from 'components/shared-components/ensure-transparency';

const LandForm = ({
  startingYear,
  agriculturalLandInHectare,
  openCultivatedLandInHectare,
  greenlandInHectare,
  validationError,
}) => {
  const { data: startingYears = [] } = useGetOrganisationStartingYearsQuery();
  const startingYearOptions = startingYears.map(year => ({ label: year, value: year }));
  const isWarningVisible = moment().month() === 3;

  return (
    <div className="grid col-12 col-bleed direction-row">
      <div className="col-12 col-bleed-x">
        <div className="col-6 col-bleed">
          <Field name="customerSince" initialValue={startingYear}>
            {({ input, meta }) => (
              <SelectBox
                size="tiny"
                width="large"
                label={t('farmland.starting_year')}
                placeholderText={t('farmland.starting_year_placeholder')}
                isClearable={false}
                options={startingYearOptions}
                value={startingYearOptions.find(option => option.value === input.value)}
                onChange={e => input.onChange(e.value)}
                required={true}
                touched={!meta.dirtySinceLastSubmit}
                errorMsg={meta?.submitError || (!input.value && validationError?.customerSince)}
              />
            )}
          </Field>
        </div>
        {isWarningVisible && (
          <div className="farmland__warning">{t('farmland.starting_year_warning')}</div>
        )}
      </div>
      <EnsureTransparencyComponent />
      <div className="col-12 col-bleed">
        <div className="col-4 col-bleed">
          <Field name="agriculturalLandInHectare" initialValue={agriculturalLandInHectare || ''}>
            {({ input, meta }) => (
              <Input
                size="tiny"
                type="number"
                label={t('farmland.agriculture_land_title')}
                placeholder={t('farmland.agriculture_land_placeholder')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError || validationError?.agriculturalLandInHectare}
                required={true}
                {...input}
              />
            )}
          </Field>
          <div className="farmland__form-description">
            {t('farmland.agriculture_land_description')}
          </div>
        </div>
        <div className="col-4 col-bleed-y">
          <Field name="greenlandInHectare" initialValue={greenlandInHectare || ''}>
            {({ input, meta }) => (
              <Input
                size="tiny"
                type="number"
                label={t('farmland.green_land_title')}
                placeholder={t('farmland.green_land_placeholder')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError || validationError?.greenlandInHectare}
                required={true}
                {...input}
              />
            )}
          </Field>
          <div className="farmland__form-description">{t('farmland.green_land_description')}</div>
        </div>
        <div className="col-4 col-bleed">
          <Field
            name="openCultivatedLandInHectare"
            initialValue={openCultivatedLandInHectare || ''}
          >
            {({ input, meta }) => (
              <Input
                size="tiny"
                type="number"
                label={t('farmland.open_land_title')}
                placeholder={t('farmland.open_land_placeholder')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError || validationError?.openCultivatedLandInHectare}
                required={true}
                {...input}
              />
            )}
          </Field>
          <div className="farmland__form-description">{t('farmland.open_land_description')}</div>
        </div>
      </div>
    </div>
  );
};

LandForm.defaultProps = {
  startingYear: null,
  agriculturalLandInHectare: '',
  openCultivatedLandInHectare: '',
  greenlandInHectare: '',
  validationError: {},
};

LandForm.propTypes = {
  startingYear: PropTypes.number,
  agriculturalLandInHectare: PropTypes.string,
  openCultivatedLandInHectare: PropTypes.string,
  greenlandInHectare: PropTypes.string,
  validationError: PropTypes.shape(),
};

export default LandForm;
