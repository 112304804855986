import { useGetCurrentUserQuery } from 'api/users';
import BioLogo from 'assets/bio_logo.png';
import Avatar from 'components/base-components/Avatar';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Popover from 'components/base-components/Popover';
import SidePanel from 'components/base-components/SidePanel';
import { Header } from 'components/base-components/Typography';
import BreadCrumbComponent from 'components/breadcrumb/breadcrumb-component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { useLogOutMutation } from 'api/session';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import breadcrumbRoutes from 'utils/breadcrum-helper/route-names';
import LanguageComponent from 'components/shared-components/language';
import { Link } from 'react-router-dom';
import Topnav from '../base-components/Topnav';
import TopnavMessageComponent from './message/topnav-message.component';
import TopnavTaskComponent from './task/topnav-task.component';
import './topnav.styles.scss';

export default function TopnavComponent() {
  const [isUserPopoverVisible, setIsUserPopoverVisible] = useState(false);
  const [isTaskSidePanelOpen, setIsTaskSidePanelOpen] = useState(false);
  const [isMessageSidePanelOpen, setIsMessageSidePanelOpen] = useState(false);
  const [userLogOut] = useLogOutMutation();
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();

  const { data: currentUser, isSuccess: isCurrentUserFetched } = useGetCurrentUserQuery();

  const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes(t));
  const logOut = () => {
    userLogOut()
      .unwrap()
      .then(() => (window.location.href = '/login'));
  };

  return (
    <>
      <Topnav
        imageSrc={<img src={BioLogo} alt="Bio-Suisse" height="50" width="50" />}
        pageHeader={
          <Header level={2}>{breadcrumbs[breadcrumbs.length - 1].breadcrumb.props.children}</Header>
        }
        breadcrumbs={<BreadCrumbComponent breadcrumbs={breadcrumbs} />}
        actions={[
          <LanguageComponent />,
          <IconButton
            icon={<Icon size="small" name="chat" />}
            color="default"
            onClick={() => setIsMessageSidePanelOpen(true)}
          />,
          <IconButton
            icon={<Icon size="small" name="tasks" />}
            color="default"
            onClick={() => setIsTaskSidePanelOpen(true)}
          />,
          <Popover
            className="topnav__user-popover--wrapper"
            isVisible={isUserPopoverVisible}
            onOutsideClick={() => setIsUserPopoverVisible(false)}
            direction="bottom-right"
            content={
              <>
                <div className="topnav__user-popover">
                  <Avatar
                    src={currentUser.profilePictureUrl}
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    size="large"
                  />
                  <Link to={navigationLinks.userEditPage(currentUser.id)}>
                    <IconButton
                      icon={<Icon name="edit" showBGColor={true} />}
                      color="default"
                      size="tiny"
                      label={t('common.edit_button')}
                      onClick={() => setIsUserPopoverVisible(false)}
                    />
                  </Link>

                  <div className="topnav__user-popover--name">{currentUser.name}</div>
                  <div className="topnav__user-popover--email">{currentUser.email}</div>
                  <Button
                    className="topnav__user-popover--signout"
                    label={t('common.sign_out')}
                    size="small"
                    onClick={logOut}
                  />
                </div>
              </>
            }
          >
            <div
              className="topnav__user"
              onClick={() => setIsUserPopoverVisible(!isUserPopoverVisible)}
            >
              {isCurrentUserFetched && (
                <Avatar
                  src={currentUser.profilePictureUrl}
                  firstName={currentUser?.firstName}
                  lastName={currentUser?.lastName}
                  size="small"
                  bgColor={5}
                />
              )}
            </div>
          </Popover>,
        ]}
        showBackButton={false}
      />
      {isTaskSidePanelOpen && (
        <SidePanel
          className="topnav__sidepanel--task"
          isOpened={isTaskSidePanelOpen}
          title={t('tasks.title')}
          body={<TopnavTaskComponent setIsSidePanelOpen={setIsTaskSidePanelOpen} />}
          onClose={() => setIsTaskSidePanelOpen(false)}
          onClick={() => setIsTaskSidePanelOpen(false)}
        />
      )}
      {isMessageSidePanelOpen && (
        <SidePanel
          className="topnav__sidepanel--message"
          isOpened={isMessageSidePanelOpen}
          title={t('messages.title')}
          body={<TopnavMessageComponent setIsSidePanelOpen={setIsMessageSidePanelOpen} />}
          onClose={() => setIsMessageSidePanelOpen(false)}
          onClick={() => setIsMessageSidePanelOpen(false)}
        />
      )}
    </>
  );
}
