import { Font, StyleSheet } from '@react-pdf/renderer';
import FuturaLight from 'assets/fonts/futuralight.ttf';
import {
  FONT_SIZE_9,
  FONT_SIZE_36,
  FONT_WEIGHT_BOLD,
} from '../../styles/variables';

Font.register({
  family: 'futura-light', src: FuturaLight,
});

const certificateStyles = StyleSheet.create({

  body: {
    flexGrow: 1,
    marginHorizontal: 70,
  },

  headerLogo: {
    height: 117,
    width: '100%',
    marginTop: 30,
  },

  image: {
    height: '85%',
    objectFit: 'contain',
    width: '22.7%',
    margin: '0 auto',
  },

  address: {
    textAlign: 'center',
    fontWeight: FONT_WEIGHT_BOLD,
  },
  mainContact: {
    textAlign: 'center',
  },
  title: {
    fontSize: FONT_SIZE_36,
    textAlign: 'center',
    fontFamily: 'futura-light',
    marginBottom: 40,
  },

  subTitle: {
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: FONT_WEIGHT_BOLD,
  },

  description: {
    marginBottom: 3,
  },

  subFooter: {
    marginTop: 15,
  },

  subFooterText: {
    fontWeight: FONT_WEIGHT_BOLD,
  },

  footer: {
    marginTop: 25,
    fontSize: FONT_SIZE_9,
    fontWeight: FONT_WEIGHT_BOLD,
    marginBottom: 10,
  },

  user: {
    fontWeight: FONT_WEIGHT_BOLD,
  },
});

export default certificateStyles;
