import { Document, Image, Page, View } from '@react-pdf/renderer';
import BioLogo from 'assets/bio_logo_large.png';
import styles from 'components/pdf/styles/styles';
import React from 'react';
import PropTypes from 'prop-types';
import Content from './content/content.view';
import FrontPage from './content/front-page.view';
import ProducerContractFooter from './footer.view';
import producerContractStyles from './producer-contract.styles';

const ProducerContractPDF = ({ organisation }) => {
  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, ...producerContractStyles.page }}>
        <Image style={producerContractStyles.headerLogo} src={BioLogo} />
        <View style={styles.body}>
          <FrontPage organisation={organisation} />
          <Content organisation={organisation} />
        </View>
        <ProducerContractFooter showInLastPageOnly={true} />
      </Page>
    </Document>
  );
};

ProducerContractPDF.propTypes = {
  organisation: PropTypes.shape().isRequired,
};

export default ProducerContractPDF;
