import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ORGANIZATION } from 'utils/entity-type';
import { MANUAL, REVIEW_CREATED_ANNERKENNUNG, REVIEW_INVOICE_JOB } from 'utils/task-types';
import { yearMonthDayWithHyphen } from '../../../utils/date-time-formatter';
import { UNSENT } from '../../../utils/recognition-utils';
import { parseTaskTitle } from '../task-helper';

const TaskTitle = ({ task }) => {
  const { t } = useTranslation();

  const TAB_MAPPER = {
    review_education_declaration: 'educations',
    review_supplier_certificate_declaration: 'supplier_certificate',
    review_goods_declaration: 'goods',
    review_product_declaration: 'products',
    review_control_body_declaration: 'control_body',
    review_control_flow_schedule: 'control_body',
    review_ingredient_reserve_additional_task: 'control_body',
    review_restricted_ingredient_additional_task: 'control_body',
    review_logo_attachment_additional_task: 'control_body',
    review_accounting_process_additional_task: 'control_body',
    review_control_submitted_by_auditor: 'control_body',
    perform_negative_report_additional_tasks: 'control_body',
    review_certification_status_discrepancy: 'control_body',
  };

  const tab = TAB_MAPPER[task.type];

  const isAutomated = task.type !== MANUAL;

  const taskTitle = () => {
    if (task.type === REVIEW_INVOICE_JOB) {
      return `/invoices/list?jobIds=${task.referenceableId}`;
    } else if (task.type === REVIEW_CREATED_ANNERKENNUNG) {
      return `/recognition/${yearMonthDayWithHyphen(task.createdAt)}?tab=${UNSENT}`;
    } else if (task.type !== MANUAL) {
      const organizationId =
        task.referenceableType === ORGANIZATION
          ? task.referenceableId
          : task.title.match(/Organization#(\d+)/)[1];
      return `/organisations/${organizationId}?tab=${tab}`;
    }
  };

  return (
    <div className="task__wrapper-navigate">
      <span className="task__wrapper-navigate--title">{parseTaskTitle(task)}</span>
      {isAutomated && (
        <Link
          type="button"
          size="tiny"
          className={'task__wrapper-navigate--link'}
          modifier="info"
          href={taskTitle()}
        >
          {t('shared.action.view')}
          <Icon name="externalLink" size="tiny" />
        </Link>
      )}
    </div>
  );
};

TaskTitle.propTypes = {
  task: PropTypes.shape({
    referenceableType: PropTypes.string.isRequired,
    referenceableId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default TaskTitle;
