import { Font, StyleSheet } from '@react-pdf/renderer';
import FuturaNormal from 'assets/fonts/FuturaStdBook.otf';
import FuturaBold from 'assets/fonts/FuturaStdBold.otf';
import {
  BASE_LINE_HEIGHT,
  COLOR_BLACK,
  FONT_SIZE_10,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_SIZE_8_5,
  FONT_WEIGHT_BOLD,
} from './variables';

const BASE_MARGIN = 72;

Font.register({
  family: 'Futura',
  fonts: [{ src: FuturaNormal }, { src: FuturaBold, fontWeight: FONT_WEIGHT_BOLD }],
});

const tvdStyles = StyleSheet.create({
  page: {
    fontFamily: 'Futura',
    lineHeight: BASE_LINE_HEIGHT,
    fontSize: FONT_SIZE_10,
  },
  imageLogo: {
    height: 40,
    width: '100%',
  },
  headerLogo: {
    height: 98,
    width: '100%',
    objectFit: 'contain',
    marginBottom: 25,
    marginTop: 20,
  },
  headerTitle: {
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: FONT_SIZE_16,
    marginTop: 10,
  },
  body: {
    flexGrow: 1,
  },
  contentBody: {
    marginHorizontal: 72,
  },
  secondPageContentBody: {
    marginLeft: 90,
    marginRight: 40,
  },
  secondPageBottomView: {
    width: '100%',
    fontSize: FONT_SIZE_8_5,
    marginTop: 50,
  },
  secondPageBottomLinkWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  secondPageBottomLinks: {
    color: COLOR_BLACK,
    textDecoration: 'none',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textBold: {
    fontWeight: FONT_WEIGHT_BOLD,
    letterSpacing: '-0.5px',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexRowItem: {
    width: '100%',
  },
  ppaInfoContainer: {
    width: '39%',
    borderBottom: '1 solid #000',
    justifyContent: 'space-between',
    marginLeft: BASE_MARGIN,
    alignContent: 'center',
    alignItems: 'center',
    fontSize: FONT_SIZE_8_5,
  },
  ppaInfo: {
    width: 120,
    border: '1 solid #000',
    marginBottom: 3,
    paddingTop: 2,
  },
  ppaText: {
    fontSize: FONT_SIZE_20,
    lineHeight: 0.5,
    fontWeight: FONT_WEIGHT_BOLD,
    marginLeft: 2,
  },
  ppaNumber: {
    paddingLeft: 10,
  },
  postNumber: {
    marginTop: 2,
    fontSize: 7,
  },
  stickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  sticker: {
    width: '50%',
    height: 103.2,
    marginBottom: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: 14,
  },
  stickerPart: {
    height: '100%',
    padding: 5,
    paddingTop: 7,
  },
  stickeLabel: {
    width: '100%',
    fontSize: 5,
  },
  leftStickerDivider: {
    height: '50%',
  },
  logo: {
    width: 53,
    marginLeft: 2,
    objectFit: 'contain',
    paddingLeft: 5,
    paddingRight: 5,
  },
  yearContainer: {
    display: 'flex',
    height: '100%',
    minWidth: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  yearText: {
    fontSize: '30',
    lineHeight: 1,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  logosContainer: {
    height: '100%',
  },
  infoContainer: {
    height: '100%',
    justifyContent: 'center',
  },
  bsNr: {
    width: 70,
    height: '100%',
    justifyContent: 'center',
  },
  bsNrText: {
    paddingLeft: 8,
  },
  address: {
    fontSize: 8.5,
    lineHeight: 1,
  },
  suisseLogoContainer: {
    width: '30%',
  },
  suisseLogo: {
    width: 48,
    objectFit: 'contain',
    position: 'relative',
    right: 6,
  },
  tvdWrapper: {
    height: '100%',
    flexGrow: 1,
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 3,
  },
  tvdNumberContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  tvdNumber: {
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: 10,
  },
  tvdListItemStyle: {
    position: 'relative',
    marginTop: 1,
    paddingLeft: 15,
  },
  listPadding: {
    position: 'relative',
    marginTop: 1,
    paddingLeft: 14,
    marginLeft: 1,
  },
  stickerText: {
    width: '40%',
    padding: 10,
    height: 80,
    marginTop: 20,
  },
  tvdBarcode: {
    width: '100%',
    objectFit: 'contain',
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop30: {
    marginTop: 30,
  },
  marginTop40: {
    marginTop: 40,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginLeft10: {
    marginLeft: 10,
  },
  signImage: {
    height: 40,
    width: 60,
  },
  extraLabels: {
    fontSize: 8,
    marginTop: 4,
  },
});

export default tvdStyles;
