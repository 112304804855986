import React from 'react';
import { t } from 'i18next';
import { useGetDairyFarmsQuery } from 'api/producers/productions';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import OverviewContent from 'components/organisations/view/overview/overview-content.component';

const MarketData = ({ organisationDataLakeAttribute, isFetching, productionId }) => {
  const { data: dairyFarms = [] } = useGetDairyFarmsQuery(
    { productionId: productionId },
    { skip: !productionId },
  );

  const applicableFarm = dairyFarms.find(farm => farm.subType == 'milk_cow');

  return (
    <Card className="organisation-overview__card super-office-details__container-element">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
      ) : (
        <>
          <span className="organisation-overview__card-title">
            {t('organisation.super_office.attributes.market_data.title')}
          </span>
          <div className="organisation-overview__contact-wrapper">
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.provisional_certificate_status',
              )}
              value={organisationDataLakeAttribute?.provisionalCertificateStatus}
              noMargin={true}
            />
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.entitled_for_direct_payment',
              )}
              value={
                organisationDataLakeAttribute?.entitledForDirectPayment
                  ? t('shared.action.yes')
                  : t('shared.action.no')
              }
              noMargin={true}
            />
          </div>
          <hr className="super-office-details__horizontal-line" />
          <span className="super-office-details__card-subtitle">
            {t('organisation.super_office.attributes.market_data.attributes.milk')}
          </span>
          <div className="organisation-overview__contact-wrapper">
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.organic_milk_producer',
              )}
              value={organisationDataLakeAttribute?.organicMilkProducersOrganization}
            />
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.compulsory_membership_milk_buyer',
              )}
              value={organisationDataLakeAttribute?.compulsoryMembershipMilkYear}
            />
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.first_milk_buyer',
              )}
              value={
                organisationDataLakeAttribute?.firstMilkBuyer ||
                applicableFarm?.productionDetails?.firstMilkBuyer
              }
            />
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.organic_milk_organisation',
              )}
              value={applicableFarm?.productionDetails?.organicMilkProducerOrganization}
            />
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.main_part_of_milk',
              )}
              value={
                applicableFarm?.productionDetails?.milkProcessingSource &&
                t(
                  `milk_production.source.${applicableFarm?.productionDetails?.milkProcessingSource}`,
                )
              }
              noMargin={true}
            />
          </div>
          <hr className="super-office-details__horizontal-line" />
          <span className="super-office-details__card-subtitle">
            {t('organisation.super_office.attributes.market_data.attributes.pigs')}
          </span>
          <div className="organisation-overview__contact-wrapper">
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.organic_pig_producer',
              )}
              value={organisationDataLakeAttribute?.organicPigProducerOrganization}
              noMargin={true}
            />
            <OverviewContent
              label={t(
                'organisation.super_office.attributes.market_data.attributes.plus_health_program_for_pigs',
              )}
              value={organisationDataLakeAttribute?.plusHealthProgram}
              noMargin={true}
            />
          </div>
        </>
      )}
    </Card>
  );
};

MarketData.propTypes = {
  organisationDataLakeAttribute: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  productionId: PropTypes.number.isRequired,
};

export default MarketData;
