import React from 'react';
import SanitizedHTML from 'components/shared-components/sanitized-html';
import markdownToHTML from 'utils/markdown-converter';

const isAutomatedTask = task => {
  const availableTypes = [
    'review_education_declaration',
    'review_education_assignee_declaration',
    'review_supplier_certificate_declaration',
    'review_goods_declaration',
    'review_product_declaration',
    'review_control_body_declaration',
    'review_control_flow_schedule',
    'review_ingredient_reserve_additional_task',
    'review_restricted_ingredient_additional_task',
    'review_logo_attachment_additional_task',
    'review_accounting_process_additional_task',
    'review_control_submitted_by_auditor',
    'perform_negative_report_additional_tasks',
    'request_control_body_survey_certificate',
    'review_invoice_job',
    'review_created_anerkennung',
  ];

  return availableTypes.includes(task?.type);
};

const parseTaskTitle = task => {
  if (isAutomatedTask(task)) return <SanitizedHTML html={markdownToHTML(task?.title)} />;

  return task?.title;
};

const parseTaskDescription = task => {
  if (isAutomatedTask(task)) return <SanitizedHTML html={markdownToHTML(task?.description)} />;

  return <SanitizedHTML html={task?.description} />;
};

export { parseTaskTitle, parseTaskDescription, isAutomatedTask };
