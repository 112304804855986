import { Text, View } from '@react-pdf/renderer';
import BioLogo from 'assets/bio_logo_large.png';
import { Break } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatAddressForPDF } from 'components/pdf/anerkennung/helpers/organization-address';
import React from 'react';
import Sticker from './sticker.view';

const StickerPage = ({ organization, barcodeImageUrl, tvdNumber, address, labels }) => {
  const year = moment().format('YY');
  const numberOfSticker = 16;

  const getOrganizationInfo = () => {
    return (
      <Text>
        {organization.name}
        <Break />
        {formatAddressForPDF(address)}
      </Text>
    );
  };

  const getLogo = () => {
    // TODO: More logo will be added based on certification status
    // https://welltravel.atlassian.net/browse/BIOS-967
    return BioLogo;
  };

  return (
    <View wrap={true}>
      <View style={tvdStyles.stickerContainer}>
        {Array.from({ length: numberOfSticker }, (_, i) => i + 1).map(item => (
          <Sticker
            key={item}
            logo={getLogo()}
            tvdNumber={tvdNumber}
            info={getOrganizationInfo()}
            year={year}
            barcodeImageUrl={barcodeImageUrl}
            labels={labels}
          />
        ))}
      </View>
    </View>
  );
};

StickerPage.propTypes = {
  address: PropTypes.shape().isRequired,
  barcodeImageUrl: PropTypes.string.isRequired,
  organization: PropTypes.shape().isRequired,
  tvdNumber: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
};

export default StickerPage;
