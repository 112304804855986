import { removeEmptyFromObject } from 'utils/object-cleanup';

export const plantValues = (values, formData) => {
  const preparePlantAttributes = (entityName, attributes) => {
    removeEmptyFromObject(attributes);
    Object.keys(attributes).forEach(attribute =>
      formData.append(
        `production_details[plant_cultivation][${entityName}][${attribute}]`,
        attributes[attribute],
      ),
    );
  };

  if (values?.arableCrops) {
    values.arableCrops.forEach(crop => {
      if (crop) {
        formData.append('production_details[plant_cultivation][arable_crops][crop_items][]', crop);
      }
    });
  }

  if (values?.fruitData) {
    Object.keys(values.fruitData).forEach(key => {
      const fruitValues = values.fruitData[key];
      fruitValues.forEach(
        ({
          fruit_type,
          number_of_trees,
          open_surface_area_in_ares,
          covered_surface_area_in_ares,
        }) => {
          if (fruit_type) {
            const fruitAttributes = {
              plant_type: key,
              fruit_type,
              number_of_trees,
              open_surface_area_in_ares,
              covered_surface_area_in_ares,
            };

            const isEmptyValueObject = removeEmptyFromObject(fruitAttributes);

            if (Object.keys(isEmptyValueObject)?.length > 2) {
              Object.keys(fruitAttributes).forEach(fruit => {
                if (fruitAttributes[fruit]) {
                  return formData.append(
                    `production_details[plant_cultivation][fruits][][${fruit}]`,
                    fruitAttributes[fruit],
                  );
                }
              });
            }
          }
        },
      );
    });
  }

  if (values.table_grapes_checkbox) {
    const tableGrapesData = {
      fruit_type: values.table_grapes_checkbox,
      open_field_cultivation: values.table_grapes__open_checkbox,
      open_surface_area_in_ares: values.table_grapes__open_field_in_hectares,
      covered_field_cultivation: values.table_grapes__covered_checkbox,
      covered_surface_area_in_ares: values.table_grapes__covered_cultivation_in_hectares,
    };

    Object.keys(tableGrapesData).map(key =>
      formData.append(
        `production_details[plant_cultivation][fruits][][${key}]`,
        tableGrapesData[key] || '',
      ),
    );
  }

  if (values.other_fruits) {
    formData.append(`production_details[plant_cultivation][fruits][][fruit_type]`, 'other');
  }

  const potatoesAttributes = {
    surface_area_in_hectares: values?.potatoes__open_field_in_hectares,
  };
  preparePlantAttributes('potatoes', potatoesAttributes);

  const vegetableAttributes = {
    open_field_in_hectares: values?.vegetables__open_field_in_hectares,
    covered_solid_land_in_ares: values?.vegetables__covered_solid_land_in_ares,
    covered_cultivation_without_solid_land_in_ares:
      values?.vegetables__covered_cultivation_without_solid_land_in_ares,
  };
  preparePlantAttributes('vegetables', vegetableAttributes);

  const mushroomAttributes = {
    open_field_in_ares: values?.mushroom_open_field_in_ares,
    champignons_quantity_in_tonnes: values?.mushroom_champigon_quantity_in_tons,
    truffles_quantity_in_tonnes: values?.mushroom_truffles_quantity_in_tons,
    covered_field_in_ares: values?.mushroom_covered_field_in_ares,
    nobles_quantity_in_tonnes: values?.mushroom_nobles_quantity_in_tonnes,
  };
  preparePlantAttributes('mushroom_farming', mushroomAttributes);

  const otherAttributes = {
    open_field_in_hectares: values?.other__open_field_in_hectares,
    covered_cultivation_in_hectares: values?.other__covered_cultivation_in_hectares,
  };
  preparePlantAttributes('others', otherAttributes);

  const ornamentalAttributes = {
    open_field_in_hectares: values?.ornamental__open_field_in_hectares,
    covered_cultivation_in_hectares: values?.ornamental__covered_cultivation_in_hectares,
  };
  preparePlantAttributes('ornamentals', ornamentalAttributes);

  const viticultureAttributes = {
    covered_field_in_hectares: values?.viticulture__covered_cultivation_in_hectares,
    open_field_in_hectares: values?.viticulture__open_field_in_hectares,
  };
  preparePlantAttributes('viticultures', viticultureAttributes);

  if (values?.herbsData) {
    values?.herbsData.forEach(
      ({ type, open_surface_area_in_ares, covered_surface_area_in_ares, cultivation_periods }) => {
        if (type) {
          const herbsAttributes = {
            type,
            open_surface_area_in_ares,
            covered_surface_area_in_ares,
          };

          Object.keys(herbsAttributes).forEach(herb => {
            if (herbsAttributes[herb]) {
              return formData.append(
                `production_details[plant_cultivation][herbs][][${herb}]`,
                herbsAttributes[herb],
              );
            }
          });

          cultivation_periods.forEach(period => {
            if (period) {
              formData.append(
                `production_details[plant_cultivation][herbs][][cultivation_periods][]`,
                period,
              );
            }
          });
        }
      },
    );
  }
};
