import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import BioLogo from 'assets/bio_logo_large.png';
import { useLanguageContext } from 'contexts/languageContext';
import styles from 'components/pdf/styles/styles';
import { STATUS_UK } from 'utils/organization-certificate-status.js';
import PdfFooter from 'components/pdf/footer/pdf-footer.component.jsx';
import organizationAddressForPdf from 'components/pdf/anerkennung/helpers/organization-address';
import { RecognitionSignatureForm } from '../../recognition/content.view.jsx';
import { Break, ListItem } from '../../shared/shared-components';
import certificateStyles from '../../styles/anerkennung/certificate';

const CertificationPDF = ({ organization, status, generationDate, user }) => {
  const { t } = useLanguageContext();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={certificateStyles.body}>
          <View style={certificateStyles.headerLogo}>
            <Image style={certificateStyles.image} src={BioLogo} />
          </View>

          <Text style={certificateStyles.title}>
            {t(`pdf.anerkennung.certificate.${status}.title`)}
          </Text>

          {user && <View style={certificateStyles.mainContact}>
            <Text style={certificateStyles.user}>{t(user.salutation)}<Break />{user.name}</Text>
          </View>}

          <Text style={certificateStyles.address}>
            {organizationAddressForPdf(organization, null, null, false)}
            <Break count={2} />
          </Text>

          <Text style={certificateStyles.subTitle}>
            {t('pdf.recognition.company_number', {
              companyNumber: organization?.debitNumber,
            })}
            <Break />
            {organization?.bioId}
          </Text>

          <Text style={certificateStyles.description}>{t(`pdf.anerkennung.certificate.${status}.description`)}</Text>

          <ListItem listStyle="bullet" style={styles.listItemContainerTopShirnk}>
            {t('pdf.anerkennung.certificate.list.item_1')}
          </ListItem>
          <ListItem listStyle="bullet" style={styles.listItemContainerTopShirnk}>
            {t('pdf.anerkennung.certificate.list.item_2')}
          </ListItem>
          <ListItem listStyle="bullet" style={styles.listItemContainerTopShirnk}>
            {t('pdf.anerkennung.certificate.list.item_3')}
          </ListItem>
          <ListItem listStyle="bullet" style={styles.listItemContainerTopShirnk}>
            {t('pdf.anerkennung.certificate.list.item_4')}
          </ListItem>
          <ListItem listStyle="bullet" style={styles.listItemContainerTopShirnk}>
            {t('pdf.anerkennung.certificate.list.item_5')}
          </ListItem>
          <ListItem listStyle="bullet" style={styles.listItemContainerTopShirnk}>
            {t('pdf.anerkennung.certificate.list.item_6')}
          </ListItem>
          <ListItem listStyle="bullet" style={styles.listItemContainerTopShirnk}>
            {t('pdf.anerkennung.certificate.list.item_7')}
          </ListItem>

          <View style={certificateStyles.subFooter}></View>
          {status === STATUS_UK &&
            <Text style={certificateStyles.subFooterText}>{t('pdf.anerkennung.certificate.uk.valid_until')}</Text>}

          <RecognitionSignatureForm />
          <Text><Break />{t('pdf.anerkennung.basel', { generationDate })}</Text>
          <Text style={certificateStyles.footer}>{t('pdf.anerkennung.certificate.footer')}</Text>
        </View>

        <PdfFooter showInFirstPageOnly={true} />

      </Page>
    </Document>
  );
};

CertificationPDF.propTypes = {
  organization: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  status: PropTypes.string.isRequired,
  generationDate: PropTypes.string.isRequired,
};

export default CertificationPDF;
