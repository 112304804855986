import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/base-components/CheckBox';
import { tvdLabelOptions } from 'utils/tvd-label-options';

const TVDLabelCheckbox = ({ title, disabledLabels, tvd }) => {
  const form = useForm();
  const { values } = useFormState();
  const { t } = useTranslation();

  const onLabelChange = (event, form, label) => {
    const selectedLabels = form.getFieldState('labels').value || [];
    const excludeMap = {
      alp: 'berg',
      berg: 'alp',
      bts_milkcow: 'bts_mothercow',
      bts_mothercow: 'bts_milkcow',
    };

    if (event.target.checked) {
      form.change(
        'labels',
        selectedLabels.filter(item => item !== excludeMap[label]).concat(label),
      );
    } else {
      form.change(
        'labels',
        selectedLabels.filter(item => item !== label),
      );
    }
  };

  const isTypeDownload = values.type === 'download';

  const isLabelEnabled = label => {
    if (values.type === 'order') return false;

    return !disabledLabels.includes(label);
  };

  useEffect(() => {
    if (!values.type) return;

    isTypeDownload ? form.change('labels', []) : form.change('labels', tvd.labels);
  }, [values.type]);

  return (
    <div className="tvd-list__sidepanel--input-field">
      <div className="tvd-list__sidepanel-label">{title}</div>
      {tvdLabelOptions.map(label => (
        <div key={label}>
          <Field name={'labels'} type="checkbox" value={label}>
            {({ input }) => (
              <CheckBox
                className="tvd-list__sidepanel--checkbox"
                isChecked={input.checked}
                size="tiny"
                label={t(`tvd.order.extra_labels.${label}`)}
                onChange={event =>
                  isTypeDownload ? onLabelChange(event, form, label) : input.onChange(event)
                }
                disabled={!isLabelEnabled(label)}
              />
            )}
          </Field>
        </div>
      ))}
    </div>
  );
};

TVDLabelCheckbox.defaultProps = {
  isOrder: false,
  tvd: {},
};

TVDLabelCheckbox.propTypes = {
  title: PropTypes.string.isRequired,
  disabledLabels: PropTypes.array.isRequired,
  tvd: PropTypes.shape(),
};

export default TVDLabelCheckbox;
