import { Image, View, Text } from '@react-pdf/renderer';
import { InputField } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { dateMonthYearWithSlash } from 'utils/date-time-formatter';
import digitalSignature1 from 'assets/digital_signature_1.png';
import digitalSignature2 from 'assets/digital_signature_2.png';

const SignatureForm = ({ organisation }) => {
  const currentDate = dateMonthYearWithSlash(new Date());
  return (
    <>
      <view style={styles.signatureForm}>
        <Text style={{ ...styles.textBold, width: '100%', marginRight: 10 }}>
          {t('producer_contract.signature_form.left.title')}
        </Text>
        <Text style={{ ...styles.textBold, width: '100%', marginLeft: 10 }}>
          {organisation.name}
        </Text>
      </view>
      <View style={{ ...styles.signatureForm, marginTop: 0 }}>
        <View style={{ width: '100%', marginRight: 10 }}>
          <InputField
            style={{ marginTop: 20 }}
            value={<Image src={digitalSignature1} style={styles.signature2} />}
            title={t('producer_contract.signature_form.left.first_input')}
          />
          <InputField
            style={{ marginTop: 44 }}
            value={<Image src={digitalSignature2} style={styles.signature3} />}
            title={t('producer_contract.signature_form.left.second_input')}
          />
          <InputField
            style={{ marginTop: 52 }}
            title={t('producer_contract.signature_form.right.third_input')}
            value={`Basel, ${currentDate}`}
            valueType="text"
          />
        </View>
        <View style={{ width: '100%', marginLeft: 10 }}>
          <InputField title={t('producer_contract.signature_form.right.first_input')} />
          <InputField
            lineStyle={{ marginTop: 30 }}
            title={t('producer_contract.signature_form.right.second_input')}
          />
          <InputField
            lineStyle={{ marginTop: 30 }}
            title={t('producer_contract.signature_form.right.first_input')}
          />
          <InputField
            lineStyle={{ marginTop: 30 }}
            title={t('producer_contract.signature_form.right.second_input')}
          />
          <InputField
            lineStyle={{ marginTop: 20 }}
            style={{ marginTop: 10 }}
            title={t('producer_contract.signature_form.right.third_input')}
          />
        </View>
      </View>
    </>
  );
};

SignatureForm.propTypes = {
  organisation: PropTypes.shape().isRequired,
};

export default SignatureForm;
