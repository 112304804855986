import { Image, Text, View } from '@react-pdf/renderer';
import { t } from 'i18next';
import presidentSignature from 'assets/signature_1.png';
import signature2 from 'assets/signature_2.png';
import React from 'react';
import PropTypes from 'prop-types';
import { Break, ListItem, Section } from '../shared/shared-components';
import recognitionStyles from '../styles/recognition';
import styles from '../styles/styles';

export const RecognitionSignatureForm = () => (
  <View style={styles.signatureForm}>
    <View style={{ width: '100%', marginRight: 50 }}>
      <Text>{t('pdf.recognition.signature_form.bio_suisse')}</Text>
      <Image style={styles.signature1} src={presidentSignature} />
      <Text>
        {t('pdf.recognition.signature_form.signature_1.name')}
        <Break />
        {t('pdf.recognition.signature_form.signature_1.designation')}
      </Text>
    </View>
    <View style={{ width: '100%', marginLeft: 20, marginRight: 20 }}>
      <Text>
        <Break count={1} />
      </Text>
      <Image style={styles.signature1} src={signature2} />
      <Text>
        {t('pdf.recognition.signature_form.signature_2.name')}
        <Break />
        {t('pdf.recognition.signature_form.signature_2.designation')}
      </Text>
    </View>
  </View>
);

const Content = ({ organizationBioId }) => {
  return (
    <View>
      <Text style={recognitionStyles.pdfTitle}>{t('pdf.recognition.title')}</Text>
      <Text>
        <Break count={2} />
      </Text>
      <Text style={recognitionStyles.pdfSubtitle}>{organizationBioId}</Text>

      <Section>
        <Text>
          {t('pdf.recognition.description.section_1')}
          <Break />
        </Text>
        <Text>{t('pdf.recognition.description.section_2')}</Text>
        <ListItem listStyle="bullet">{t('pdf.recognition.list.item_1')}</ListItem>
        <ListItem listStyle="bullet">{t('pdf.recognition.list.item_2')}</ListItem>
        <ListItem listStyle="bullet">{t('pdf.recognition.list.item_3')}</ListItem>
        <ListItem listStyle="bullet">{t('pdf.recognition.list.item_4')}</ListItem>
        <ListItem listStyle="bullet">{t('pdf.recognition.list.item_5')}</ListItem>
        <ListItem listStyle="bullet">{t('pdf.recognition.list.item_6')}</ListItem>
        <ListItem listStyle="bullet">{t('pdf.recognition.list.item_7')}</ListItem>
      </Section>

      <RecognitionSignatureForm />

      <Text>
        <Break />
      </Text>
      <Text style={styles.textBold}>{t('pdf.recognition.footer_text')}</Text>
    </View>
  );
};
Content.propTypes = {
  organizationBioId: PropTypes.string.isRequired,
};

export default Content;
