import { StyleSheet, Font } from '@react-pdf/renderer';
import FuturaNormal from 'assets/fonts/FuturaStdBook.otf';
import FuturaBold from 'assets/fonts/FuturaStdBold.otf';
import {
  FONT_SIZE_16,
  FONT_SIZE_12,
  FONT_SIZE_8_5,
  FONT_SIZE_10,
  FONT_WEIGHT_BOLD,
  BASE_LINE_HEIGHT,
  COLOR_BLACK,
  COLOR_GRAY,
} from './variables';

Font.register({
  family: 'Futura',
  fonts: [{ src: FuturaNormal }, { src: FuturaBold, fontWeight: FONT_WEIGHT_BOLD }],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Futura',
    lineHeight: BASE_LINE_HEIGHT,
    fontSize: FONT_SIZE_10,
    paddingBottom: 20,
  },
  headerLogo: {
    height: 84,
    width: '100%',
    objectFit: 'contain',
    marginBottom: 54,
    marginTop: 24,
  },
  statusLogo: {
    height: 30,
    padding: 6,
    width: 30,
  },
  body: {
    flexGrow: 1,
    marginHorizontal: 72,
  },
  checkListBody: {
    flexGrow: 1,
    marginHorizontal: 20,
  },
  addressFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 25,
    paddingTop: 10,
  },
  footerText: {
    fontSize: FONT_SIZE_8_5,
    color: COLOR_GRAY,
    fontFamily: 'Futura',
  },
  title: {
    fontSize: FONT_SIZE_16,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  heading1: {
    fontSize: FONT_SIZE_12,
    fontWeight: FONT_WEIGHT_BOLD,
    marginBottom: 4,
  },
  heading2: {
    fontSize: FONT_SIZE_10,
    fontWeight: FONT_WEIGHT_BOLD,
    marginBottom: 4,
  },
  bold: {
    fontWeight: FONT_WEIGHT_BOLD,
  },
  text: {
    fontSize: FONT_SIZE_10,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  frontPageDescription: {
    fontSize: FONT_SIZE_12,
    paddingTop: 20,
  },
  section: {
    marginTop: 20,
  },
  subSection: {
    marginBottom: 20,
  },
  listItemContainer: {
    position: 'relative',
    marginLeft: 10,
    marginTop: 6,
    paddingLeft: 15,
  },
  listItemWithNoGap: {
    marginLeft: 0,
    marginTop: 6,
    paddingLeft: 15,
    position: 'relative',
  },
  listItemContainerTopShirnk: {
    position: 'relative',
    marginLeft: 18,
    paddingLeft: 10,
  },
  listPrefixContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: 4,
    paddingBottom: 10,
  },
  listPrefix: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  square: {
    backgroundColor: COLOR_BLACK,
    width: 3,
    height: 3,
  },
  largeSquare: {
    backgroundColor: COLOR_BLACK,
    width: 6,
    height: 6,
  },
  dash: {
    backgroundColor: COLOR_BLACK,
    width: 3,
    height: 1,
  },
  bullet: {
    backgroundColor: COLOR_BLACK,
    width: 3,
    height: 3,
    borderRadius: 10,
  },
  signatureForm: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  horizontalLine: {
    width: '100%',
    height: 0.5,
    backgroundColor: COLOR_BLACK,
    marginTop: 40,
  },
  checklistHorizontalLine: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#00393f40',
    marginTop: '10px',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexRowItem: {
    width: '100%',
  },
  grid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textBold: {
    fontWeight: FONT_WEIGHT_BOLD,
  },
  checklistMarginForty: {
    marginTop: 40,
  },

  checklistMarginTen: {
    marginTop: 10,
  },

  checklistMarginTwenty: {
    marginTop: 20,
  },

  checklistMarginZero: {
    marginTop: 0,
  },

  checklistMarginTwentyBottom: {
    marginBottom: 20,
  },

  checklistMarginTwentyLeft: {
    marginLeft: 20,
  },

  checklistMarginTenLeft: {
    marginLeft: 10,
  },

  checklistMarginTenRight: {
    marginRight: 10,
  },

  checklistMarginFiveLeft: {
    marginLeft: 5,
  },

  checklistMarginFive: {
    marginTop: 5,
  },

  checkListFlexSpaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  checkListFlex: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hr: {
    width: '100%',
    height: 0.8,
    backgroundColor: '#00393f29',
    marginTop: 5,
    marginBottom: 5,
  },
  signature1: {
    width: 100,
    height: 50,
  },
  signature2: {
    width: 70,
    height: 20,
    marginLeft: 10,
  },
  signature3: {
    width: 90,
    height: 30,
    marginLeft: 10,
  },
});

export default styles;
