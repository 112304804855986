const USERS_STATUS = {
  active: 'active',
  archived: 'inactive',
  invited: 'unverified',
};

const ORGANISATIONS_STATUS = {
  aborted: 'aborted',
  active: 'active',
  inactive: 'inactive',
  verified: 'verified',
  unverified: 'unverified',
  interested: 'interested',
};

const PRODUCT_DECLARATION_STATUS = {
  active: 'active',
  inactive: 'inactive',
  draft: 'draft',
};

const DEACTIVATED = 'deactivated';

export { USERS_STATUS, ORGANISATIONS_STATUS, PRODUCT_DECLARATION_STATUS, DEACTIVATED };
