import React from 'react';
import PropTypes from 'prop-types';
import { BlobProvider } from '@react-pdf/renderer';
import Spinner from 'components/base-components/Spinner/Spinner';
import { STATUS_UK, STATUS_VK } from 'utils/organization-certificate-status';
import i18next from 'i18next';
import LanguageContext from 'contexts/languageContext';
import Link from 'components/base-components/Link';
import CoverLetterPDF from 'components/pdf/anerkennung/cover-letter/cover-letter.document.jsx';
import CertificationPDF from 'components/pdf/anerkennung/certificate/certificate.document.jsx';
import { useTranslation } from 'react-i18next';
import { useGetUserQuery } from 'api/users';

const RecognitionPDFComponent = ({ organization, certificateGenDate }) => {
  const t = i18next.getFixedT(i18next.language === 'en' ? 'de' : i18next.language);
  const { t: translate } = useTranslation();
  const { data: user = {} } = useGetUserQuery(
    { id: organization?.mainContactId },
    { skip: !organization?.mainContactId },
  );
  const certificationStatus = ['u1', 'u2', 'u3'].includes(organization?.certificationStatus)
    ? STATUS_UK
    : STATUS_VK;
  const COVER_LETTER_PDF_FILENAME = `Begleitbrief ${certificationStatus.toUpperCase()}-Anerkennung.pdf`;
  const CERTIFICATE_PDF_FILENAME = `Knospe Anerkennung ${certificationStatus.toUpperCase()} ohne Logo.pdf`;

  return (
    <>
      <div className="task__popover-content">
        <BlobProvider
          document={
            <LanguageContext.Provider value={{ t }}>
              <CoverLetterPDF
                organization={organization}
                generationDate={certificateGenDate}
                status={certificationStatus}
                user={user}
              />
            </LanguageContext.Provider>
          }
        >
          {({ blob, url, loading }) => {
            return (
              <>
                {loading ? (
                  <Spinner bgColor="none" color="success" size="tiny" />
                ) : (
                  <Link
                    download={COVER_LETTER_PDF_FILENAME}
                    type="plain"
                    size="small"
                    href={url}
                    modifier="default"
                  >
                    <div>
                      {translate('recognition.details.table.popovers.download_cover_letter')}
                    </div>
                  </Link>
                )}
              </>
            );
          }}
        </BlobProvider>
      </div>

      <div className="task__popover-content">
        <BlobProvider
          className="task__popover-content"
          document={
            <LanguageContext.Provider value={{ t }}>
              <CertificationPDF
                organization={organization}
                generationDate={certificateGenDate}
                status={certificationStatus}
                user={user}
              />
            </LanguageContext.Provider>
          }
        >
          {({ blob, url, loading }) => {
            return (
              <>
                {loading ? (
                  <Spinner bgColor="none" color="success" size="tiny" />
                ) : (
                  <Link
                    download={CERTIFICATE_PDF_FILENAME}
                    type="plain"
                    size="small"
                    href={url}
                    modifier="default"
                  >
                    <div>
                      {translate('recognition.details.table.popovers.download_certificate')}
                    </div>
                  </Link>
                )}
              </>
            );
          }}
        </BlobProvider>
      </div>
    </>
  );
};

RecognitionPDFComponent.propTypes = {
  organization: PropTypes.shape().isRequired,
  certificateGenDate: PropTypes.string.isRequired,
};

export default RecognitionPDFComponent;
