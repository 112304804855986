import { Image, Text, View } from '@react-pdf/renderer';
import tvdSuisseLogo from 'assets/tvdSuisseLogo.png';
import { Break } from 'components/pdf/shared/shared-components';
import tvdStyles from 'components/pdf/styles/tvd-styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import alp from 'assets/Alp_D-e.png';
import berg from 'assets/Berg_D-e.png';
import { t } from 'i18next';
import { FONT_WEIGHT_BOLD } from 'components/pdf/styles/variables';

const Sticker = ({ tvdNumber, logo, info, year, barcodeImageUrl, labels }) => {
  const LABELS_WITH_ICON = ['alp', 'berg'];
  const LABELS_WITHOUT_ICON = ['bts_mothercow', 'bts_milkcow'];
  const labelsString = labels
    .filter(label => LABELS_WITHOUT_ICON.includes(label))
    ?.map(label => t(`tvd.order.extra_labels.${label}`))
    .join(', ');
  const labelWithIcon = labels?.find(label => LABELS_WITH_ICON.includes(label));

  const ICON_MAPPER = { alp, berg };

  return (
    <View style={tvdStyles.sticker}>
      <View style={[tvdStyles.flexRowItem, tvdStyles.stickerPart]}>
        <View style={tvdStyles.leftStickerDivider}>
          <View style={[tvdStyles.flexRow, tvdStyles.logosContainer]}>
            <View>
              <Image source={logo} style={tvdStyles.logo} />
            </View>
            <View style={tvdStyles.yearContainer}>
              <Text style={tvdStyles.yearText}>{year}</Text>
            </View>
            <View style={tvdStyles.tvdWrapper}>
              <View style={tvdStyles.tvdNumberContainer}>
                <Text style={tvdStyles.tvdNumber}>TVD</Text>
                <Text style={tvdStyles.tvdNumber}>{tvdNumber}</Text>
              </View>
              <Image source={barcodeImageUrl} style={tvdStyles.tvdBarcode} />
            </View>
          </View>
        </View>
        <View style={tvdStyles.leftStickerDivider}>
          <View style={tvdStyles.infoContainer}>
            <View style={tvdStyles.flexRow}>
              <View style={[tvdStyles.bsNr]}>
                <Text style={tvdStyles.bsNrText}>
                  BS-Nr.
                  <Break />
                  11776
                </Text>
              </View>
              <View>
                <Text style={tvdStyles.address}>{info}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={[tvdStyles.stickerPart, tvdStyles.suisseLogoContainer]}>
        <Image source={tvdSuisseLogo} style={tvdStyles.suisseLogo} />
        <Text style={tvdStyles.extraLabels}>{labelsString}</Text>
        {labelWithIcon && (
          <View style={tvdStyles.flex}>
            <Text style={tvdStyles.stickeLabel}>
              {
                <Trans
                  i18nKey={`pdf.tvd.sticker_${labelWithIcon}`}
                  components={{ bold: <Text style={{ fontWeight: FONT_WEIGHT_BOLD }} /> }}
                />
              }
            </Text>
            <Image src={ICON_MAPPER[labelWithIcon]} style={tvdStyles.suisseLogo} />
          </View>
        )}
      </View>
    </View>
  );
};

Sticker.propTypes = {
  tvdNumber: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  barcodeImageUrl: PropTypes.string.isRequired,
  logo: PropTypes.node.isRequired,
  info: PropTypes.node.isRequired,
  labels: PropTypes.array.isRequired,
};

export default Sticker;
