import React from 'react';
import SelectBox from 'components/base-components/SelectBox';
import { FRENCH, GERMAN, ITALIAN } from 'utils/language-options';
import { Field, useFormState } from 'react-final-form';
import { useGetSpokenLanguageListQuery } from 'api/users';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const ModeOfCommunicationForm = ({ organization, validationError, menuPlacement }) => {
  const { values } = useFormState();
  const defaultLanguageCode = 'de';
  const { data: availablelanguageOptions = [] } = useGetSpokenLanguageListQuery();

  const filteredLanguages = availablelanguageOptions.filter(option => option.value.length === 2);

  const languageOptions = filteredLanguages.filter(
    option => option.value !== values?.secondSpokenLanguage?.value,
  );

  const languageOptionsByCode = languageOptions.reduce(
    (options, { label, value }) => ({ ...options, [value]: { label, value } }),
    {},
  );
  const spokenLanguageOptions =
    values?.language === ITALIAN
      ? languageOptions.filter(({ value }) => value === GERMAN || value === FRENCH)
      : languageOptions.filter(option => option.value !== values?.language);
  return (
    <>
      <div className="organisation__form--font-bold">
        {t('organisation.form.attributes.mode_of_communication')}
      </div>
      <div className="organisation__form-languages organisation__form--margin-top">
        <Field name="language" initialValue={organization?.language || defaultLanguageCode}>
          {({ input, meta }) => (
            <SelectBox
              {...input}
              size="tiny"
              width="full"
              isClearable={false}
              label={t('user_general.prefered_language')}
              placeholderText={t('user_general.placeholder')}
              onChange={e => input.onChange(e.value)}
              value={languageOptions.find(option => option.value === input.value)}
              required={true}
              selectedValue={input.label}
              options={languageOptions}
              errorMsg={meta.submitError}
              menuPlacement={menuPlacement}
            />
          )}
        </Field>
        <Field
          name="secondSpokenLanguage"
          initialValue={languageOptionsByCode[organization?.secondSpokenLanguage]?.value}
        >
          {({ input, meta }) => (
            <SelectBox
              {...input}
              size="tiny"
              width="full"
              label={t('user_general.spoken_language')}
              placeholderText={t('user_general.placeholder')}
              isClearable={true}
              value={spokenLanguageOptions.find(option => option.value === input.value)}
              selectedValue={input.label}
              options={spokenLanguageOptions}
              onChange={element => {
                if (!element) {
                  return input.onChange(null);
                }
                return input.onChange(element.value);
              }}
              errorMsg={meta.submitError || (!input.value && validationError?.secondSpokenLanguage)}
              touched={!meta.dirtySinceLastSubmit}
              required={values?.language === ITALIAN}
              menuPlacement={menuPlacement}
            />
          )}
        </Field>
      </div>
    </>
  );
};

ModeOfCommunicationForm.defaultProps = {
  organization: {},
  validationError: {},
  menuPlacement: 'top',
};

ModeOfCommunicationForm.propTypes = {
  organization: PropTypes.shape(),
  validationError: PropTypes.shape(),
  menuPlacement: PropTypes.string,
};

export default ModeOfCommunicationForm;
