import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'i18next';
import { useGetBeeHiveProductionQuery } from 'api/producers/productions';
import SharedProductCultivationComponent from '../shared/shared-product-cultivation.component';

const BeeHiveProduction = ({ farmlandId }) => {
  const { data: beeHiveProductionData } = useGetBeeHiveProductionQuery(
    {
      productionId: farmlandId,
    },
    { skip: !farmlandId },
  );

  const fields = [
    {
      name: 'numberOfBeeColonies',
      initialValue: beeHiveProductionData?.numberOfColonies,
      label: t('plant_cultivation.bee_hives.bee_colonies'),
      placeholder: t('plant_cultivation.shared.surface_area_input_placeholder'),
      isRequired: true,
    },
    {
      name: 'quantityOfHoney',
      initialValue: beeHiveProductionData?.honeyProducedKg,
      label: t('plant_cultivation.bee_hives.quantity_of_honey'),
      placeholder: t('plant_cultivation.shared.enter_quantity'),
      isRequired: true,
    },
  ];

  return (
    <SharedProductCultivationComponent
      label={t('plant_cultivation.bee_hives.bee_keeping')}
      fieldName="bee"
      icon="bee"
      name="bee"
      title={t('plant_cultivation.bee_hives.bee_keeping')}
      fields={fields}
      isTypePresent={true}
    />
  );
};

BeeHiveProduction.propTypes = {
  farmlandId: PropTypes.number.isRequired,
};

export default BeeHiveProduction;
