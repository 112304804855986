import { useGetProductionQuery, useGetProductionsQuery } from 'api/producers/productions';
import { Tabs } from 'components/base-components/RTabs';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import PLANT_CULTIVATION from 'utils/plant-cultivation';
import { useForm } from 'react-final-form';
import Egg from './Egg/index.component';
import BeeHiveProduction from './bee-hive/bee-hive-production.component';
import FishProduction from './fish-farming/fish-production.component';
import GardeningComponent from './gardening/gardening.component';
import GreenAreaProduction from './green-areas/green-area-production.component';
import MeatTypesComponent from './meat/meat-types/meat-types.component';
import MilkTypesComponent from './milk/milk-types.component';
import OtherFarmProduction from './other-farms/other-farm-production.component';
import './plant-cultivate/plant-cultivation.styles.scss';
import PlantTypesComponent from './plant-cultivate/plant-types/plant-types.component';
import PrivateParkProduction from './private-park/private-park-production.component';
import ProducerTypeWithIcon from './shared/production-type-with-icon.component';
import EmptyProducerContent from './shared/empty-producer-content.component';

const ProductionCultivation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const form = useForm();

  const { values } = useFormState();

  const TAB_MAPPER = {
    plant_cultivation: 0,
    meat_production: 1,
    egg: 2,
    milk: 3,
    fish: 4,
    bee: 5,
    animal: 6,
    garden: 7,
    greenArea: 8,
    privatePark: 9,
  };

  const { data: farmlandData } = useGetProductionsQuery(
    {
      organizationId: id,
    },
    { skip: !id },
  );

  const { data: plantCultivationData } = useGetProductionQuery(
    {
      productionId: farmlandData?.id,
    },
    { skip: !farmlandData },
  );

  const currentTab = searchParams.get('tab');

  useEffect(() => {
    const selectedProductionDatas = values?.production?.filter(data => !!data);
    if (selectedProductionDatas) {
      setSearchParams({ tab: selectedProductionDatas[0] });
    }
  }, [values?.production?.length]);

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ tab: selectedTab });
  };

  const isTabHidden = tabName => !values?.production?.includes(tabName);

  const onChangeSubTypeSelection = (main, subType, checked) => {
    let removedSubTypes = form.getState().values.removedSubTypes || {};
    let associatedSubTypes = removedSubTypes[main] || [];
    if (checked) {
      associatedSubTypes = associatedSubTypes.filter(item => item !== subType);
    } else {
      associatedSubTypes.push(subType);
    }

    if (associatedSubTypes.length > 0) {
      removedSubTypes = { ...removedSubTypes, [main]: associatedSubTypes };
    } else {
      delete removedSubTypes[main];
    }

    form.change('removedSubTypes', removedSubTypes);
  };

  return (
    <div>
      <div className="col-bleed">
        {currentTab === 'undefined' ? (
          <EmptyProducerContent />
        ) : (
          <div>
            <Tabs
              className="plant-cultivation__tabs"
              controlled={true}
              selectedTabKey={TAB_MAPPER[currentTab]}
              isConditional={!values?.production?.some(data => !!data)}
              items={[
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="plantTab"
                      i18nTranslation={t('plant_cultivation.tab.plant')}
                    />
                  ),
                  key: TAB_MAPPER.plant,
                  hidden: isTabHidden(PLANT_CULTIVATION.plant_cultivation),
                  getContent: () => (
                    <PlantTypesComponent
                      plantCultivationData={plantCultivationData}
                      values={values}
                      onChangeSubTypeSelection={onChangeSubTypeSelection}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="meat_production"
                      i18nTranslation={t('plant_cultivation.tab.meat')}
                    />
                  ),
                  key: TAB_MAPPER.meat,
                  hidden: isTabHidden(PLANT_CULTIVATION.meat_production),
                  getContent: () => (
                    <MeatTypesComponent onChangeSubTypeSelection={onChangeSubTypeSelection} />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="egg"
                      i18nTranslation={t('plant_cultivation.tab.egg')}
                    />
                  ),
                  key: TAB_MAPPER.egg,
                  hidden: isTabHidden(PLANT_CULTIVATION.egg),
                  getContent: () => (
                    <Egg
                      id={farmlandData?.id}
                      onChangeSubTypeSelection={onChangeSubTypeSelection}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="milk"
                      i18nTranslation={t('plant_cultivation.tab.milk')}
                    />
                  ),
                  key: TAB_MAPPER.milk,
                  hidden: isTabHidden(PLANT_CULTIVATION.milk),
                  getContent: () => (
                    <MilkTypesComponent onChangeSubTypeSelection={onChangeSubTypeSelection} />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name={'fish'}
                      i18nTranslation={t('plant_cultivation.tab.fish')}
                    />
                  ),
                  key: TAB_MAPPER.fish,
                  hidden: isTabHidden(PLANT_CULTIVATION.fish),
                  getContent: () => <FishProduction farmlandId={farmlandData?.id} />,
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name={'bee'}
                      i18nTranslation={t('plant_cultivation.bee_hives.bee')}
                    />
                  ),
                  key: TAB_MAPPER.bee,
                  hidden: isTabHidden(PLANT_CULTIVATION.bee),
                  getContent: () => <BeeHiveProduction farmlandId={farmlandData?.id} />,
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name={'animal'}
                      i18nTranslation={t('plant_cultivation.tab.animal')}
                    />
                  ),
                  key: TAB_MAPPER.animal,
                  hidden: isTabHidden(PLANT_CULTIVATION.animal),
                  getContent: () => <OtherFarmProduction productionId={farmlandData?.id} />,
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="garden"
                      i18nTranslation={t('plant_cultivation.tab.garden')}
                    />
                  ),
                  key: TAB_MAPPER.garden,
                  hidden: isTabHidden(PLANT_CULTIVATION.garden),
                  getContent: () => <GardeningComponent productionId={farmlandData?.id} />,
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="green_area"
                      i18nTranslation={t('plant_cultivation.tab.green_area')}
                    />
                  ),
                  key: TAB_MAPPER.greenArea,
                  hidden: isTabHidden(PLANT_CULTIVATION.greenArea),
                  getContent: () => (
                    <GreenAreaProduction values={values} productionId={farmlandData?.id} />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="private_park"
                      i18nTranslation={t('plant_cultivation.tab.private_park')}
                    />
                  ),
                  key: TAB_MAPPER.privatePark,
                  hidden: isTabHidden(PLANT_CULTIVATION.privatePark),
                  getContent: () => (
                    <PrivateParkProduction values={values} productionId={farmlandData?.id} />
                  ),
                },
              ]}
              onChange={onChangeTab}
            />
          </div>
        )}
      </div>
    </div>
  );
};
ProductionCultivation.defaultProps = {
  plantCultivationData: [],
};

ProductionCultivation.propTypes = {
  plantCultivationData: PropTypes.arrayOf(PropTypes.string),
};

export default ProductionCultivation;
