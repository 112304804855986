import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useGetRecognitionQuery } from 'api/recognition';
import { dateMonthYearFormatter } from 'utils/date-time-formatter.js';
import classNames from 'classnames';
import Popover from 'components/base-components/Popover';
import RecognitionPDFComponent from 'components/recognition/details/recognition-pdf.component';

const Anerkennung = ({ organisation, noMargin }) => {
  const [showPopover, setShowPopover] = useState(false);
  const { data: certificate } = useGetRecognitionQuery(
    {
      recognitionId: organisation?.lastRecognitionId,
    },
    { skip: !organisation?.lastRecognitionId },
  );

  return (
    <div
      className={classNames('organisation-overview__content', {
        'organisation-overview__content--margin-none': noMargin,
      })}
    >
      <span className="organisation-overview__content-label">
        {t('organisation_view.overview.attributes.status.anerkennung')}
      </span>
      {organisation?.lastRecognitionId ? (
        <Popover
          className="task__popover-wrapper"
          direction="bottom-left"
          isVisible={showPopover}
          onOutsideClick={() => setShowPopover(false)}
          content={
            <div className="task__popover">
              {organisation && (
                <RecognitionPDFComponent
                  organization={organisation}
                  certificateGenDate={dateMonthYearFormatter(certificate?.createdAt)}
                />
              )}
            </div>
          }
        >
          <span
            className="organisation-overview__latest-anerkennung"
            onClick={() => setShowPopover(true)}
          >
            {t('organisation_view.overview.attributes.status.anerkennung_link', {
              bioId: organisation.bioId,
              certificationStatus: organisation.certificationStatus,
            })}
          </span>
        </Popover>
      ) : (
        <span className="organisation-overview__content-value--disabled">
          {t('common.not_applicable')}
        </span>
      )}
    </div>
  );
};

Anerkennung.defaultProps = {
  noMargin: false,
};

Anerkennung.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  noMargin: PropTypes.bool,
};

export default Anerkennung;
