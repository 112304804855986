import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from '@react-pdf/renderer';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import ADDRESS_TYPE from 'utils/address-type';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { Break } from 'components/pdf/shared/shared-components';
import organizationAddressForPdf from 'components/pdf/anerkennung/helpers/organization-address';
import producerCoverLetterStyles from './producer-cover-letter';

const ProducerCoverLetterContent = ({ organisation, user }) => {
  const currentDate = dateMonthYearFormatter(new Date());
  return (
    <>
      {organisation.name}
      <Break />
      {organizationAddressForPdf(organisation, ADDRESS_TYPE.mailing, 'coverLetter')}
      <Break count={3} />
      Basel, {currentDate} / {organisation.bioId}
      <Break count={2} />
      <Text style={producerCoverLetterStyles.pdfTitle}>
        {t('producer_contract.cover_letter.salutation_title')}
        <Break />
        {t('producer_contract.cover_letter.salutation_text')}
      </Text>
      <Break count={2} />
      {user?.salutation} {user?.name}
      <Break count={2} />
      <Text>
        {t('producer_contract.cover_letter.first_paragraph', {
          current_year: organisation.customerSince,
        })}
        <Break count={2} />
        {t('producer_contract.cover_letter.second_paragraph')}
        <Break count={2} />
        <Text style={producerCoverLetterStyles.pdfTitle}>
          {t('producer_contract.cover_letter.third_paragraph')}
        </Text>
        <Break count={2} />
        <Trans
          i18nKey="producer_contract.cover_letter.fourth_paragraph"
          components={{
            anchor1: <Link src="https://www.bio-suisse.ch/de.html" />,
            anchor2: <Link src="https://www.bioattualita.ch/" />,
          }}
        />
        <Break count={2} />
        <Trans
          i18nKey="producer_contract.cover_letter.fifth_paragraph"
          components={{
            anchor1: <Link src="verlag@bioaktuell.ch" />,
          }}
        />
        <Break count={2} />
        <Trans
          i18nKey="producer_contract.cover_letter.sixth_paragraph"
          components={{
            anchor1: <Link src="https://www.administration@bio-suisse.ch" />,
          }}
        />
        <Break count={2} />
        {t('producer_contract.cover_letter.seventh_paragraph')}
        <Break count={3} />
        {t('producer_contract.cover_letter.best_regards')}
        <Break count={2} />
        {t('producer_contract.cover_letter.sender_of_the_mail')}
        <Break count={6} />
        {t('producer_contract.cover_letter.eighth_paragraph')}
      </Text>
    </>
  );
};

ProducerCoverLetterContent.propTypes = {
  organisation: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default ProducerCoverLetterContent;
