import React from 'react';
import { Document, Page, Image, Text, View, Link } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import BioLogo from 'assets/bio_logo_large.png';
import { useLanguageContext } from 'contexts/languageContext';
import { STATUS_VK } from 'utils/organization-certificate-status';
import Footer from 'components/pdf/recognition/footer.view';
import { yearFromDate } from 'utils/date-time-formatter';
import organizationAddressForPdf from 'components/pdf/anerkennung/helpers/organization-address';
import coverLetterStyles from 'components/pdf/styles/anerkennung/cover-letter';
import { Break, ListItem } from '../../shared/shared-components';

const CoverLetterPDF = ({ organization, generationDate, status, user }) => {
  const { t } = useLanguageContext();
  const orgCustomerSince = yearFromDate(organization.customerSince)
  return (
    <Document>
      <Page size="A4" style={coverLetterStyles.page}>
        <Image style={coverLetterStyles.headerLogo} src={BioLogo} />
        <View style={coverLetterStyles.body}>
          <Text>
            {organization.name}
            <Break />
            {organizationAddressForPdf(organization)}
            <Break count={2} />
            {t('pdf.anerkennung.basel', { generationDate })}
            /{organization?.bioId}
            /FOF
            <Break count={2} />
          </Text>

          <Text style={coverLetterStyles.subject}>
            {t(`pdf.anerkennung.cover-letter.${status}.subject`)}
          </Text>


          <Text>{user.salutation}{' '}{user.name}</Text>

          <Break count={2} />

          <Text style={coverLetterStyles.description}>
            {t(`pdf.anerkennung.cover-letter.${status}.description.section_1`, { orgCustomerSince })}
            {status === STATUS_VK && <Link src="https://www.easy-cert.com/htm/zertifikate.htm">www.easy-cert.ch</Link>}
            {t(`pdf.anerkennung.cover-letter.${status}.description.section_2`)}
          </Text>

          <Link src="http://www.bio-suisse.ch/produzenten" style={coverLetterStyles.bottomMargin}>
            www.bio-suisse.ch/produzenten
          </Link>

          <View style={coverLetterStyles.table}>
            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem listStyle="bullet">
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_1`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_1`)}
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem listStyle="bullet">
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_2`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_2`)}
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem listStyle="bullet">
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_3`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_3`)}
                  <Link src='https://www.bio-diversitaet.ch/de'>
                    www.bio-diversitaet.ch
                  </Link>
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem listStyle="bullet">
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_4`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  <Link src='http://www.biomondo.ch/'>
                    www.biomondo.ch
                  </Link>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_4`)}
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem listStyle="bullet">
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_5`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_5`)}
                </Text>
                <Link src='http://www.bioaktuell.ch/grundlagen/bioregelwerk'>
                  www.bioaktuell.ch/grundlagen/bioregelwerk
                </Link>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem listStyle="bullet">
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_6`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  <Link src='http://www.bioaktuell.ch/'>
                    www.bioaktuell.ch
                  </Link> {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_6`)}
                </Text>
              </View>
            </View>
          </View>

          <Text>
            {t(`pdf.anerkennung.cover-letter.${status}.question`)}
            <Link src={`mailto: administration@bio-suisse.ch`}>
              administration@bio-suisse.ch
            </Link>
            <Break />
            {
              status === STATUS_VK &&
              <Text><Break />{t(`pdf.anerkennung.cover-letter.${status}.best_wish`)}</Text>
            }
            <Break count={2} />
            Freundliche Grüsse
            <Break />
            Bio Suisse
            <Break count={2} />
            Flurina Forsblom
            <Break />
            Data Scientist / IT
            <Break count={2} />
            {t(`pdf.anerkennung.cover-letter.${status}.from`)}
          </Text>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

CoverLetterPDF.propTypes = {
  organization: PropTypes.shape().isRequired,
  generationDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
};

export default CoverLetterPDF;
