import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import Input from 'components/base-components/Input';
import CountrySelector from 'components/shared-components/country-selector';
import CantonSelector from '../../../shared-components/canton-selector';

const AddressField = ({ parentKey, isDisabled, values, showAddressName, validationError }) => {
  const { t } = useTranslation();

  return (
    <div className="organisation__form-address">
      <Field name={`${parentKey}.id`}>
        {({ input, meta }) => <input type="hidden" {...input} />}
      </Field>
      {showAddressName && (
        <Field name={`${parentKey}.addressName`} initialValue={values.addressName}>
          {({ input, meta }) => (
            <Input
              size="tiny"
              className="organisation__form--margin-top"
              label={t('shared.label.address.address_name')}
              placeholder={t('shared.placeholder.address.address_name')}
              fullWidth={true}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError}
              disabled={isDisabled}
              {...input}
            />
          )}
        </Field>
      )}
      <Field name={`${parentKey}.line1`} initialValue={values?.line1}>
        {({ input, meta }) => (
          <Input
            size="tiny"
            className="organisation__form--margin-top"
            label={t('shared.label.address.line_1')}
            placeholder={t('shared.placeholder.address.line_1')}
            fullWidth={true}
            required={true}
            touched={!meta.dirtySinceLastSubmit}
            error={meta.submitError || (!input.value && validationError?.line1)}
            disabled={isDisabled}
            {...input}
          />
        )}
      </Field>
      <Field name={`${parentKey}.line2`} initialValue={values?.line2}>
        {({ input, meta }) => (
          <Input
            size="tiny"
            className="organisation__form--margin-top"
            label={t('shared.label.address.line_2')}
            placeholder={t('shared.placeholder.address.line_2')}
            fullWidth={true}
            touched={!meta.dirtySinceLastSubmit}
            error={meta.submitError}
            disabled={isDisabled}
            {...input}
          />
        )}
      </Field>
      <div className="organisation__form-address-flex">
        <Field name={`${parentKey}.city`} initialValue={values.city}>
          {({ input, meta }) => (
            <Input
              size="tiny"
              className="organisation__form-address-city"
              label={t('shared.label.address.city')}
              placeholder={t('shared.placeholder.address.city')}
              fullWidth={true}
              required={true}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || (!input.value && validationError?.city)}
              disabled={isDisabled}
              {...input}
            />
          )}
        </Field>
        <Field name={`${parentKey}.zipCode`} initialValue={values.zipCode}>
          {({ input, meta }) => (
            <Input
              size="tiny"
              className="organisation__form-address-zip-code"
              label={t('shared.label.address.zip_code')}
              placeholder={t('shared.placeholder.address.zip_code')}
              fullWidth={true}
              required={false}
              touched={!meta.dirtySinceLastSubmit}
              error={
                meta.submitError ||
                (input.value && input.value.length !== 4 && validationError?.zipCode)
              }
              disabled={isDisabled}
              {...input}
            />
          )}
        </Field>
        <Field name={`${parentKey}.canton`} initialValue={values?.canton}>
          {({ input, meta }) => (
            <CantonSelector
              {...input}
              size="tiny"
              width="large"
              className="organisation__form-address-canton"
              required={true}
              isClearable={false}
              errorMsg={meta.submitError || (!input.value && validationError?.canton)}
              touched={!meta.dirtySinceLastSubmit}
              label={t('shared.label.address.canton')}
              placeholderText={t('shared.placeholder.address.country')}
              parentKey={parentKey}
              value={input.value}
              onChange={e => input.onChange(e.value)}
              isDisabled={isDisabled}
            />
          )}
        </Field>
        <CountrySelector parentKey={parentKey} initialValue={values.country} />
      </div>
    </div>
  );
};
AddressField.defaultProps = {
  isDisabled: false,
  values: {},
  showAddressName: false,
  validationError: {},
};

AddressField.propTypes = {
  parentKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  values: PropTypes.shape(),
  showAddressName: PropTypes.bool,
  validationError: PropTypes.shape(),
};

export default AddressField;
