import { groupBy } from 'utils/groupBy';
import ADDRESS_TYPE from 'utils/address-type';
import { MOBILE, PHONE } from 'utils/contact_type';

const hasRequiredFields = partner => {
  const { firstName, lastName, email } = partner;
  return firstName && lastName && email;
};

const getValidationErrors = (status, errors, activeIndex, values) => {
  let errorMessages = {};

  const isDGVERequired = values.production?.some(item =>
    ['egg', 'meat_production', 'milk', 'animal'].includes(item),
  );

  if (isDGVERequired && !values?.dgve) {
    errorMessages = { dgve: 'is missing' };
  }

  if (status === 422) {
    const generalErrors = errors.organization || errors;

    errorMessages = {
      uid: generalErrors.uid,
      industry: generalErrors.industry,
      registeredName: generalErrors.name,
      secondSpokenLanguage: generalErrors.secondSpokenLanguage,
      type: generalErrors.type,
    };

    errorMessages['subOrganizations'] = errors?.subOrganizations;

    if (activeIndex === 1) {
      if (!values?.legalForm || !values?.numberOfPartners) {
        errorMessages = { ...errorMessages, partner: 'is missing' };
      } else {
        if ((values?.operationManagers || []).length === 0) {
          errorMessages = { ...errorMessages, operationManager: true };
        }
        if (!values?.mainContactEmail) {
          errorMessages = { ...errorMessages, mainContact: true };
        }
      }

      if (values?.legalForm && values?.numberOfPartners) {
        if (!values?.companyType) {
          errorMessages = { ...errorMessages, companyType: true };
        }

        let filteredLegalFormPartners = values?.legalFormPartners;

        if (values.numberOfPartners < values.legalFormPartners.length) {
          filteredLegalFormPartners = values.legalFormPartners.slice(0, values.numberOfPartners);
        }

        if (!filteredLegalFormPartners.every(hasRequiredFields)) {
          errorMessages = { ...errorMessages, assignments: true };
        }

        if (values?.otherPartner && !hasRequiredFields(values?.otherPartner)) {
          errorMessages = { ...errorMessages, assignments: true };
        }

        if (errors?.organization?.assignments) {
          errorMessages = { ...errorMessages, assignments: errors.organization.assignments };
        }
      }
    }

    if (activeIndex === 2) {
      if (errors.addresses) {
        errors.addresses.forEach(address => {
          const errorAttributes = {
            zipCode: address.zipCode,
            city: address.city,
            country: address.countryCode,
            line1: address.line1,
            canton: address.canton,
          };

          if (address.main) {
            errorMessages = { ...errorMessages, mainAddress: errorAttributes };
          } else if (address.type === ADDRESS_TYPE.invoice) {
            errorMessages = { ...errorMessages, billingAddress: errorAttributes };
          } else if (address.type === ADDRESS_TYPE.mailing) {
            errorMessages = { ...errorMessages, mailingAddress: errorAttributes };
          }
        });
      }

      if (errors.phoneNumbers) {
        const errorByType = groupBy(errors.phoneNumbers, 'type');

        errorMessages = {
          ...errorMessages,
          phone: errorByType[PHONE]?.at(0)?.number,
          MOBILE: errorByType[MOBILE]?.at(0)?.number,
        };
      }
    }

    if (activeIndex === 4 && errors.productions) {
      errorMessages = {
        ...errorMessages,
        agriculturalLandInHectare: errors.productions.agriculturalLandInHectare,
        greenlandInHectare: errors.productions.greenlandInHectare,
        openCultivatedLandInHectare: errors.productions.openCultivatedLandInHectare,
        customerSince: generalErrors.customerSince,
      };
    }

    if (activeIndex === 5 && errors) {
      errorMessages = {
        ...errorMessages,
        productionError: 'Error on production data',
      };
    }

    if (activeIndex === 6 && errors?.tvdStickerNumbers) {
      errorMessages = {
        ...errorMessages,
        tvdStickerNumbers: errors?.tvdStickerNumbers,
      };
    }

    return errorMessages;
  }

  return errorMessages;
};

export default getValidationErrors;
