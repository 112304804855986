import Avatar from 'components/base-components/Avatar/Avatar';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Input from 'components/base-components/Input';
import Spinner from 'components/base-components/Spinner';
import Tooltip from 'components/base-components/Tooltip';
import AvatarModal from 'components/shared-components/AvatarModal';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import '../organisations.styles.scss';
import ModeOfCommunicationForm from './mode-of-communication/index.component';

const GeneralForm = ({
  organization,
  isFetchingOrganizationRegistry,
  setUidSearchQuery,
  organizationRegistry,
  validationError,
}) => {
  const { t } = useTranslation();

  const onSearchRegisteredOrganization = debounce(500, value => {
    setUidSearchQuery(value);
  });

  return (
    <Fragment>
      <span className="organisation__form--font-bold">
        {t('organisation.form.attributes.organisation_logo')}
      </span>
      <div className="organisation-image__wrapper organisation__form--margin-top">
        <Field name="logo">
          {({ input }) => (
            <Fragment>
              <Avatar
                src={organization?.logoUrl || (input.value && URL.createObjectURL(input.value))}
                size="huge"
              />
              <AvatarModal updateAvatar={image => input.onChange(image)} />
            </Fragment>
          )}
        </Field>
      </div>
      <span className="organisation__form--font-bold">
        {t('organisation.form.attributes.general_info')}
      </span>
      <Field name="uid">
        {({ input, meta }) => (
          <div className="organisation__form-modal--uid organisation__form--margin-top">
            <Input
              size="tiny"
              label={
                <>
                  <div className="organisation__form-modal--uid-position">
                    <span>{t('organisation.form.attributes.uid')}</span>
                    {!organization && (
                      <span className="organisation__form-modal--uid-position__icon">
                        <Tooltip
                          content={t('organisation.form.attributes.uid_hint')}
                          type="inverse"
                          position="right-top"
                          size="small"
                          showArrow={true}
                        >
                          <IconButton
                            icon={<Icon name="info" size="tiny" />}
                            color="default"
                            size="tiny"
                            isIconOnly={true}
                          />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                </>
              }
              placeholder={t('organisation.form.placeholder.uid')}
              value={input.value}
              onChange={value => {
                input.onChange(value);
                onSearchRegisteredOrganization(value);
              }}
              disabled={!!organization?.id}
            />
            {isFetchingOrganizationRegistry && (
              <div className="organisation__form-modal--uid-spinner">
                <Spinner bgColor="none" color="success" size="tiny" />
              </div>
            )}
          </div>
        )}
      </Field>
      <div className="organisation__form-sub-label organisation__form--margin-top organisation__form--disabled">
        <Trans
          i18nKey="organisation.form.attributes.uid_label"
          components={{
            anchor: (
              <a
                href="https://www.uid.admin.ch"
                target="_blank"
                rel="noreferrer"
                className="organisation__form-sub-label--color"
              >
                <i aria-hidden="true"></i>
              </a>
            ),
          }}
        />
      </div>
      <Field name="registeredName" initialValue={organizationRegistry?.organizationLegalName}>
        {({ input, meta }) => (
          <Input
            size="tiny"
            className="organisation__form--margin-top"
            label={t('organisation.form.attributes.registered_name')}
            placeholder={t('organisation.form.placeholder.name')}
            fullWidth={true}
            required={true}
            width="full"
            error={meta.submitError || (!input.value && validationError?.registeredName)}
            touched={!meta.dirtySinceLastSubmit}
            {...input}
          />
        )}
      </Field>
      <Field name="additionalName" initialValue={organizationRegistry?.organizationAdditionalName}>
        {({ input, meta }) => (
          <Input
            size="tiny"
            className="organisation__form--margin-top margin-bottom-20"
            label={t('organisation.form.attributes.organisation_additional_name')}
            placeholder={t('organisation.form.placeholder.organisation_additional_name')}
            fullWidth={true}
            touched={!meta.dirtySinceLastSubmit}
            {...input}
          />
        )}
      </Field>
      <ModeOfCommunicationForm organization={organization} validationError={validationError} />
    </Fragment>
  );
};

GeneralForm.defaultProps = {
  organization: null,
  organizationRegistry: null,
  validationError: {},
};

GeneralForm.propTypes = {
  organization: PropTypes.shape(),
  isFetchingOrganizationRegistry: PropTypes.bool.isRequired,
  setUidSearchQuery: PropTypes.func.isRequired,
  organizationRegistry: PropTypes.shape(),
  validationError: PropTypes.shape(),
};

export default GeneralForm;
